<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div>
    <div class="app-content-box bg-gray profile-comments">
  <div class="wrapper container">
    <div class="row mt-4">
      <div class="col-3">
        <img :src="coverUrl + book.image" :alt="book.title" class="w-100">
      </div>
      <div class="col-9 pl-0">
        <h3 class="book-side-title-alt">{{book.tytul}}</h3>
        <p class="book-side-author-alt">{{book.autor}}</p>
      </div>
        <div class="mt-3 mb-3 rating-wrapper">
          <star-rating :increment="0.5" active-color="#000"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :animate='animate' :show-rating="false" :star-size=40
            :rounded-corners='rounded' :rating="rating" @rating-selected ="setRating">
          </star-rating>
        </div>
        <div class="container text-center mt-2">
          <div>Ocena: {{avgRating}}</div>
          <div v-if="myRating">Twoja ocena: {{myRating}}</div>
        </div>
        <div class="star-wrapper ml-3 mt-3 mb-3">
          <div><span>★</span> - fatalna</div>
          <div><span>★★</span> - słaba</div>
          <div><span>★★★</span> - dobra</div>
          <div><span>★★★★</span> - świetna</div>
          <div><span>★★★★★</span> - wybitna</div>
        </div>
    </div>
  </div>
      <div class="container book-description">
        <p>{{reviewContent}}</p>
      </div>
  </div>
  <div class="floating-add-comment">
  <div class="container">
    <div class="add-comment-box full">
      <form action="" @click.stop.prevent>
        <div class="add-comment-group">
          <textarea name="" id="" cols="30" rows="100" placeholder="napisz recenzję" class="autogrow" v-model="message"></textarea>
          <button type="submit" @click="submit"><i class="fas fa-paper-plane"></i></button>
        </div>
      </form>
    </div>
  </div>
  </div>
    </div>
    <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal(false)">
      <h3 class="text-center mb-4 modal-main-title">Ocena i opinia zostały dodane</h3>
      <div><strong>Dziękujemy, że podzieliłeś się swoim zdaniem.</strong></div>
      <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
    </ModalSlot>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
import StarRating from 'vue-star-rating'
export default {
  name: 'ReviewAdd',
  components: {
    TopMenuSubheadline,
    StarRating,
    ModalSlot
  },
  props: {
    id: [String, Number]
  },
  async created () {
    await this.getBook()
  },
  data () {
    return {
      title: 'napisz recenzje',
      message: '',
      reviewContent: '',
      book: [],
      rating: 0,
      myRating: 0,
      avgRating: 0,
      borderWidth: 2,
      animate: true,
      rounded: true,
      isPopupShown: false,
      revInfo: ''
    }
  },
  methods: {
    getBook () {
      this.$https('/book/' + this.id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.book = data.response.book
          this.myRating = this.book.myRating
          if (this.avgRating % 1 === 0) {
            this.avgRating = parseFloat(this.book.rating, 10)
          } else {
            this.avgRating = parseFloat(this.book.rating, 10)
            this.avgRating = this.avgRating.toFixed(1)
          }
          this.rating = parseFloat(this.book.rating, 10)
        // this.isBookLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    closeModal (value) {
      this.isPopupShown = value
      this.$router.push(`/recenzja/${this.revInfo.book_id}/${this.revInfo.id}`)
    },
    submit () {
      this.message = this.message.trim()
      const getData = `content=${this.message}`
      this.$https('/book/' + this.id + '/review', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.revInfo = response.data.response
        this.message = ''
        this.isPopupShown = true
      })
        .catch(error => {
          console.log('', error)
        })
    },
    setRating (rating) {
      const getData = `value=${rating}`
      this.$https('/rate/book/' + this.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.getBook()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
    padding-top: 20px;
    height: 100%;
  }
  .rating-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .star-wrapper {
    span {
      font-size: 22px;
    }
  }
  .app-content-box {
    min-height: 100vh;
  }
  .floating-add-comment,
  .floating-msg-box {
    position: fixed;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #d5d5d5;
    padding: 7px 0;
    background: #f1f1f1;
    bottom: 60px;
    left: 0;

    .add-comment-box,
    .add-msg-box {
      $avatar-size: 34px;

      position: relative;
      padding-left: ($avatar-size + 5px);
      min-height: $avatar-size;

      &.full {
        padding-left: 0;
      }

      .add-comment-avatar,
      .add-msg-avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: $avatar-size;
        height: $avatar-size;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: inline-block;
          width: 100%;
        }
      }

      .add-comment-group,
      .add-msg-group {
        position: relative;

        button[type="submit"] {
          position: absolute;
          bottom: -65%;
          right: 0;
          border: 0;
          background: none;
          font-size: 16px;
          line-height: 16px;
          padding: 8px 10px 8px 5px;
          color: #d0d0d0;
          z-index: 2;
        }
        textarea {
          transition: height .2s ease;
          position: relative;
          z-index: 1;
          border: 1px solid #d0d0d0;
          padding: 8px 35px 8px 10px;
          border-radius: 15px;
          color: #d0d0d0;
          width: 100%;
          max-width: 100%;
          font-size: 11px;
          line-height: 15px;
          max-height: 20vh;
          resize: none;
          transition: .3s ease max-height;

          &:active, &:focus {
            transition: height .2s ease;
            position: relative;
            z-index: 1;
            border: 1px solid #d0d0d0;
            padding: 8px 35px 8px 10px;
            border-radius: 15px;
            color: #d0d0d0;
            width: 100%;
            max-width: 100%;
            font-size: 11px;
            line-height: 15px;
            max-height: 50vh;
            resize: none;
            transition: .3s ease max-height;
          }

          &::-webkit-input-placeholder { /* Edge */
            color: #d0d0d0;
            opacity: 1;
          }
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #d0d0d0;
            opacity: 1;
          }
          &::placeholder {
            color: #d0d0d0;
            opacity: 1;
          }

          &:not(:placeholder-shown) {
            color: #000;

            & ~ button[type="submit"] {
              color: #000;
            }
          }
        }
      }
    }
}
</style>
